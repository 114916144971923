import { STORAGE_KEYS } from "../constants/storageKeys";
import { DailyOutcome, SaveState, StartDayToDailyOutcome } from "../types";

export function saveState(saveState: SaveState) {
  localStorage.setItem(STORAGE_KEYS.DAILY_SAVE, JSON.stringify(saveState));
}

export function updateCurrentStreak(newStreak: number) {
  localStorage.setItem(STORAGE_KEYS.CURRENT_STREAK_SAVE, newStreak.toString());
}

export function updateMaxStreak(newStreak: number, maxStreak: number) {
  if (newStreak > maxStreak) {
    localStorage.setItem(STORAGE_KEYS.MAX_STREAK_SAVE, newStreak.toString());
  }
}

export function saveDailyOutcome(
  startDayToDailyOutcome: StartDayToDailyOutcome
) {
  localStorage.setItem(
    STORAGE_KEYS.DAILY_OUTCOME_SAVE,
    JSON.stringify(startDayToDailyOutcome)
  );
}
