import React from "react";
import styled from "styled-components";
import { COLOR } from "../constants/color";

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 30px;
  height: 40px;
  border-radius: 20%;
  background-color: ${COLOR.KEYBOARD};
  color: ${COLOR.TEXT_COLOR};
  border: 0px;
  padding: 0;
`;

const LetterText = styled.div`
  padding-bottom: 5px;
`;

interface Props {
  letter: string;
  onPress: (letter: string) => void;
}

const KeyboardButton: React.FC<Props> = ({ letter, onPress }) => {
  return (
    <Container onClick={() => onPress(letter)}>
      <LetterText>{letter}</LetterText>
    </Container>
  );
};

export default KeyboardButton;
