import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLOR } from "../constants/color";
import { WordOutcome } from "./WordContainer";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  font-size: 18px;
  border: 1px solid ${COLOR.BORDER};
  background-color: ${COLOR.LIGHTER_GRAY};
  color: ${COLOR.TEXT_COLOR};
  transition: background-color 0.2s;

  &.spacing {
    margin-right: 2px;
  }

  &.pop {
    animation-name: pop;
    animation-duration: 0.2s;
    -webkit-animation-name: pop;
    -webkit-animation-duration: 0.2s;
  }

  &.correct {
    animation-name: correct;
    animation-duration: 0.5s;
    animation-delay: ${(props: { animDelay: number }) => props.animDelay}s;
    animation-fill-mode: forwards;
    -webkit-animation-name: correct;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-delay: ${(props: { animDelay: number }) =>
      props.animDelay}s;
    -webkit-animation-fill-mode: forwards;
  }

  &.incorrect {
    animation-name: incorrect;
    animation-duration: 0.5s;
    animation-delay: ${(props: { animDelay: number }) => props.animDelay}s;
    animation-fill-mode: forwards;
    -webkit-animation-name: incorrect;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-delay: ${(props: { animDelay: number }) =>
      props.animDelay}s;
    -webkit-animation-fill-mode: forwards;
  }

  @keyframes pop {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes correct {
    0% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      background-color: ${COLOR.LIGHTER_GRAY};
    }

    60% {
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      background-color: ${COLOR.LIGHTER_GRAY};
    }

    80% {
      transform: translateY(3px);
      -webkit-transform: translateY(3px);
      background-color: ${COLOR.CORRECT_TRY};
    }

    100% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      background-color: ${COLOR.CORRECT_TRY};
    }
  }

  @keyframes incorrect {
    0% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
    }

    60% {
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
    }

    100% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
    }
  }
`;

const TypeIndicator = styled.div`
  height: 80%;
  width: 2px;
  border-radius: 20%;
  background-color: white;

  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

interface Props {
  letter: string;
  showTypeIndicator: boolean;
  hasSpacing: boolean;
  wordOutcome: WordOutcome;
  revealAnimDelay: number;
}

const LetterBlock: React.FC<Props> = ({
  letter,
  showTypeIndicator,
  hasSpacing,
  wordOutcome,
  revealAnimDelay,
}) => {
  const [shouldPop, setShouldPop] = useState(false);
  useEffect(() => {
    if (letter !== "") {
      setShouldPop(true);
      setTimeout(() => {
        setShouldPop(false);
      }, 1000);
    }
  }, [letter]);
  const popClass = shouldPop ? "pop" : "";
  const spacingClass = hasSpacing ? "spacing" : "";
  const wordOutcomeClass =
    wordOutcome === WordOutcome.Correct
      ? "correct"
      : wordOutcome === WordOutcome.Incorrect
      ? "incorrect"
      : "";
  return (
    <Container
      animDelay={revealAnimDelay}
      className={
        "letter-block " + spacingClass + " " + popClass + " " + wordOutcomeClass
      }
    >
      {showTypeIndicator ? <TypeIndicator /> : null}
      {letter}
    </Container>
  );
};

export default LetterBlock;
