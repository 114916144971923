import React from "react";
import styled from "styled-components";
import { TryOutcome } from "../types";
import CoreSpacer from "./CoreSpacer";

import Try from "./Try";

const TriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface Props {
  triesList: TryOutcome[];
}

const Tries: React.FC<Props> = ({ triesList }) => {
  return (
    <TriesContainer>
      <Try outcome={triesList[0]} />
      <CoreSpacer isHorizontal size="extraSmall" />

      <Try outcome={triesList[1]} />
      <CoreSpacer isHorizontal size="extraSmall" />

      <Try outcome={triesList[2]} />
    </TriesContainer>
  );
};

export default Tries;
