import React from "react";
import styled from "styled-components";

import { TryOutcome } from "../types";
import { COLOR } from "../constants/color";

const Container = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${COLOR.LIGHTER_GRAY};
  border: 2px solid ${COLOR.BORDER};
  flex-shrink: 0;

  &.Incorrect {
    background-color: ${COLOR.INCORRECT_TRY};
  }

  &.SemiCorrect {
    background-color: ${COLOR.SEMI_CORRECT_TRY};
  }

  &.Correct {
    background-color: ${COLOR.CORRECT_TRY};
  }
`;

interface Props {
  outcome: TryOutcome;
}

const Try: React.FC<Props> = ({ outcome }) => {
  return <Container className={TryOutcome[outcome]}></Container>;
};

export default Try;
