import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faClose,
  faBars,
  faCircleQuestion,
  faPoll,
  faShare,
  faShareAlt,
  faCircleInfo,
  faBackspace,
} from "@fortawesome/free-solid-svg-icons";

export function initializeFontAwesome() {
  library.add(
    faCheck,
    faClose,
    faBars,
    faCircleQuestion,
    faPoll,
    faShare,
    faShareAlt,
    faCircleInfo,
    faBackspace
  );
}
