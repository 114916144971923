import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LetterBlock from "./LetterBlock";

const Container = styled.div`
  display: flex;
`;

export enum WordOutcome {
  None = 0,
  Incorrect,
  Correct,
}

interface Props {
  outcome: WordOutcome;
  word: string[];
  wordIndex: number;
  currentWordIndex: number;
  currentLetterIndex: number;
  onRevealComplete: (wordIndex: number) => void;
  onClick: (wordIndex: number) => void;
}

const ANIM_DURATION_SECONDS = 0.6;

const WordContainer: React.FC<Props> = ({
  outcome,
  word,
  wordIndex,
  currentWordIndex,
  currentLetterIndex,
  onRevealComplete,
  onClick,
}) => {
  useEffect(() => {
    if (!outcome) {
      return;
    }

    const timeoutSeconds =
      wordIndex * ANIM_DURATION_SECONDS + ANIM_DURATION_SECONDS;
    setTimeout(() => {
      onRevealComplete(wordIndex);
    }, (timeoutSeconds + 0.1) * 1000);
  }, [outcome]);

  return (
    <Container onClick={() => onClick(wordIndex)}>
      {word.map((letter, letterIndex) => (
        <LetterBlock
          letter={letter}
          showTypeIndicator={
            // currentWordIndex === wordIndex && letterIndex === 0 && !letter
            outcome !== WordOutcome.Correct &&
            currentWordIndex === wordIndex &&
            currentLetterIndex === letterIndex
          }
          wordOutcome={outcome}
          revealAnimDelay={wordIndex * ANIM_DURATION_SECONDS}
          hasSpacing={letterIndex < word.length - 1}
        />
      ))}
    </Container>
  );
};

export default WordContainer;
