import React from "react";
import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLOR } from "../constants/color";

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 45px;
  height: 40px;
  border-radius: 20%;
  background-color: ${COLOR.KEYBOARD};
  color: ${COLOR.TEXT_COLOR};
  outline: none;
  user-select: none;
  border: 0px;
  padding: 0;
`;

interface Props {
  icon: IconProp;
  onPress: () => void;
}

const KeyboardActionButton: React.FC<Props> = ({ icon, onPress }) => {
  return (
    <Container onClick={onPress}>
      <FontAwesomeIcon icon={icon} />
    </Container>
  );
};

export default KeyboardActionButton;
