import React, { useState } from "react";
import styled from "styled-components";
import { COLOR } from "../constants/color";

const Container = styled.div`
  position: relative;
  width: fit-content;
  padding: 5px;
  min-width: 130px;
`;

const Hider = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLOR.REVEAL_HIDER};
  border-radius: 5px;
  text-align: center;
  color: ${COLOR.TEXT_COLOR};
  font-size: 14px;
  cursor: pointer;
`;

const AnswerText = styled.div`
  text-align: center;
  color: ${COLOR.TEXT_COLOR};
  font-weight: bold;
  font-size: 14px;
`;

interface Props {
  text: string;
}

const RevealText: React.FC<Props> = (props) => {
  const [isRevealed, setIsRevealed] = useState(false);

  function onReveal() {
    setIsRevealed(true);
  }

  return (
    <Container>
      {!isRevealed ? (
        <Hider onClick={onReveal}>Click to see answer</Hider>
      ) : null}
      <AnswerText>{props.text}</AnswerText>
    </Container>
  );
};

export default RevealText;
