import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLOR } from "../constants/color";
import { getStartOfDay, padTime } from "../utils/time";

const Container = styled.div`
  font-size: 24px;
  color: ${COLOR.TEXT_COLOR};
`;

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const startOfDayMS = getStartOfDay();
    const nextDayMS = startOfDayMS + 24 * 60 * 60 * 1000;
    let difference = +new Date(nextDayMS) - +new Date();

    let timeLeft = {
      days: "0",
      hours: "0",
      minutes: "0",
      seconds: "0",
    };

    if (difference > 0) {
      timeLeft = {
        days: padTime(Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: padTime(Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: padTime(Math.floor((difference / 1000 / 60) % 60)),
        seconds: padTime(Math.floor((difference / 1000) % 60)),
      };
    }

    return timeLeft;
  }

  return (
    <Container>
      {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
    </Container>
  );
};

export default Timer;
