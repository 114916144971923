export enum TryOutcome {
  None,
  Incorrect,
  SemiCorrect,
  Correct,
}

export enum MatchState {
  Default,
  Win,
  Defeat,
}

export interface Puzzle {
  answers: string[];
  definition: string;
  imageUrl: string;
  backgroundColor: string;
}

export interface SaveState {
  currentTry: number;
  triesList: TryOutcome[];
  matchState: MatchState;
  lastSavedMS: number;
}

export interface DailyOutcome {
  startOfDayString: string;
  matchState: MatchState;
  triesList: TryOutcome[];
}

export interface StartDayToDailyOutcome {
  [startOfDayString: string]: DailyOutcome;
}
