import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLOR } from "../constants/color";
import CoreSpacer from "./CoreSpacer";
import Timer from "./Timer";
import Tries from "./Tries";
import { MatchState, StartDayToDailyOutcome, TryOutcome } from "../types";

const MAX_MODAL_WIDTH = 500;

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;

  &.visible {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: auto;
  width: 80vw;
  height: 70vh;
  max-width: ${MAX_MODAL_WIDTH}px;
  background-color: ${COLOR.BACKGROUND};
  border: 2px solid ${COLOR.BORDER};
`;

const OutcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
`;

const HeaderText = styled.h2`
  color: ${COLOR.TEXT_COLOR};
  text-align: center;
`;

const ShareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  width: 200px;
  padding: 10px;
  background-color: ${COLOR.CORRECT_TRY};
  color: ${COLOR.TEXT_COLOR};
  font-size: 24px;
  cursor: pointer;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${COLOR.TEXT_COLOR};
  font-size: 24px;
  cursor: pointer;
`;

const WinHeaderText = styled.h1`
  color: ${COLOR.CORRECT_TRY};
  text-align: center;
`;

const DefeatHeaderText = styled.h1`
  color: ${COLOR.INCORRECT_TRY};
  text-align: center;
`;

const AnswerDescriptionText = styled.p`
  text-align: center;
  color: ${COLOR.TEXT_COLOR};
`;

const AnswerText = styled.span`
  color: ${COLOR.TEXT_COLOR};
  font-weight: bold;
  text-decoration: underline;
`;

const DefinitionText = styled.p`
  text-align: center;
  color: ${COLOR.TEXT_COLOR};
  font-style: italic;
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLOR.TEXT_COLOR};
`;

const StatText = styled.div`
  font-size: 14px;
  width: 50px;
  text-align: center;
`;

interface Props {
  isVisible: boolean;
  answer: string;
  answerDefinition: string;
  matchState: MatchState;
  triesList: TryOutcome[];
  startDayToDailyOutcome: StartDayToDailyOutcome;
  currentStreak: number;
  maxStreak: number;
  onClose: () => void;
  onShare: () => void;
}

const OutcomeModal: React.FC<Props> = ({
  isVisible,
  answer,
  answerDefinition,
  matchState,
  triesList,
  currentStreak,
  maxStreak,
  onClose,
  onShare,
}) => {
  function onShareClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    onShare();
  }

  function onModalClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const visibilityClass = isVisible ? "visible" : "";

  return (
    <Container className={visibilityClass} onClick={onClose}>
      <ModalContainer onClick={onModalClick}>
        <OutcomeContainer>
          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon="close" />
          </CloseButton>
          {matchState === MatchState.Win ? (
            <>
              <WinHeaderText>Nice Work!</WinHeaderText>
              <CoreSpacer size="extraSmall" />
            </>
          ) : null}
          {matchState === MatchState.Defeat ? (
            <>
              <DefeatHeaderText>Try again tomorrow!</DefeatHeaderText>
              <CoreSpacer size="extraSmall" />
            </>
          ) : null}
          {matchState !== MatchState.Default ? (
            <>
              <Tries triesList={triesList} />
              <CoreSpacer size="extraSmall" />
            </>
          ) : null}

          <StatsContainer>
            <ColumnSection>
              <h1>{currentStreak}</h1>
              <StatText>Current Streak</StatText>
            </ColumnSection>
            <CoreSpacer isHorizontal size="large" />

            <ColumnSection>
              <h1>{maxStreak}</h1>
              <StatText>Max Streak</StatText>
            </ColumnSection>
          </StatsContainer>
          <CoreSpacer size="large" />
        </OutcomeContainer>

        {matchState !== MatchState.Default ? (
          <ColumnSection>
            <AnswerDescriptionText>
              The answer is <AnswerText>{answer}</AnswerText>
            </AnswerDescriptionText>
            <DefinitionText>Definition: {answerDefinition}</DefinitionText>
            <CoreSpacer size="large" />
          </ColumnSection>
        ) : null}

        <ColumnSection>
          <HeaderText>Next Puzzle</HeaderText>
          <Timer />
          <CoreSpacer size="small" />
          {matchState !== MatchState.Default ? (
            <ShareButton onClick={onShareClick}>
              Share
              <CoreSpacer isHorizontal size="extraSmall" />
              <FontAwesomeIcon icon="share-alt" />
            </ShareButton>
          ) : null}
        </ColumnSection>
      </ModalContainer>
    </Container>
  );
};

export default OutcomeModal;
