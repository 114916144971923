import React from "react";
import styled from "styled-components";
import {
  FIRST_ROW,
  NUMBER_ROW,
  SECOND_ROW,
  THIRD_ROW,
} from "../constants/keyboard";
import CoreSpacer from "./CoreSpacer";
import KeyboardActionButton from "./KeyboardActionButton";

import KeyboardButton from "./KeyboardButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  onBackPress: () => void;
  onEnterPress: () => void;
  onLetterPress: (letter: string) => void;
  includeNumbers?: boolean;
}

const Keyboard: React.FC<Props> = ({
  onBackPress,
  onEnterPress,
  onLetterPress,
  includeNumbers,
}) => {
  return (
    <Container>
      {includeNumbers ? (
        <>
          <Row>
            {NUMBER_ROW.map((key, i) => (
              <>
                <KeyboardButton letter={key} onPress={onLetterPress} />
                {i < NUMBER_ROW.length ? (
                  <CoreSpacer isHorizontal size="extraSmall" />
                ) : null}
              </>
            ))}
          </Row>
          <CoreSpacer size="small" />
        </>
      ) : null}
      <Row>
        {FIRST_ROW.map((key, i) => (
          <>
            <KeyboardButton letter={key} onPress={onLetterPress} />
            {i < FIRST_ROW.length - 1 ? (
              <CoreSpacer isHorizontal size="extraSmall" />
            ) : null}
          </>
        ))}
      </Row>
      <CoreSpacer size="small" />

      <Row>
        {SECOND_ROW.map((key, i) => (
          <>
            <KeyboardButton letter={key} onPress={onLetterPress} />
            {i < SECOND_ROW.length - 1 ? (
              <CoreSpacer isHorizontal size="extraSmall" />
            ) : null}
          </>
        ))}
      </Row>
      <CoreSpacer size="small" />

      <Row>
        <KeyboardActionButton icon="check" onPress={onEnterPress} />
        <CoreSpacer isHorizontal size="extraSmall" />
        {THIRD_ROW.map((key, i) => (
          <>
            <KeyboardButton letter={key} onPress={onLetterPress} />
            <CoreSpacer isHorizontal size="extraSmall" />
          </>
        ))}
        <KeyboardActionButton icon="backspace" onPress={onBackPress} />
      </Row>
    </Container>
  );
};

export default Keyboard;
