import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { COLOR } from "../constants/color";
import CoreSpacer from "./CoreSpacer";

const HEADER_HEIGHT = 50;
const HEADER_BORDER_HEIGHT = 1;
const HEADER_SPACING = 10;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  margin-bottom: ${HEADER_SPACING}px;
  border-bottom: ${HEADER_BORDER_HEIGHT}px solid ${COLOR.BORDER};
`;

const HeaderButton = styled.div`
  font-size: 24px;
  color: ${COLOR.TEXT_COLOR};
  cursor: pointer;
  width: fit-content;
  padding: 5px;
`;

const LogoSection = styled.div`
  flex: 2;
  font-family: muli;
  font-weight: bold;
  font-size: 26px;
  color: white;
  text-align: center;
`;

const RightHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 10px;
`;

const LeftHeaderSection = styled.div`
  flex: 1;
  padding-left: 10px;
`;

interface Props {
  onMenuOpen: () => void;
  onHelpOpen: () => void;
  onInfoOpen: () => void;
  onOutcomeOpen: () => void;
}

const Header: React.FC<Props> = ({ onMenuOpen, onHelpOpen, onOutcomeOpen }) => {
  return (
    <Container>
      <LeftHeaderSection>
        {/* <HeaderButton onClick={onMenuOpen}>
          <FontAwesomeIcon icon="bars"></FontAwesomeIcon>
        </HeaderButton> */}
      </LeftHeaderSection>
      <LogoSection>zupple</LogoSection>
      <RightHeaderSection>
        <HeaderButton onClick={onHelpOpen}>
          <FontAwesomeIcon icon="circle-question"></FontAwesomeIcon>
        </HeaderButton>
        <CoreSpacer isHorizontal size="extraSmall" />

        {/* <HeaderButton onClick={onInfoOpen}>
          <FontAwesomeIcon icon="circle-info"></FontAwesomeIcon>
        </HeaderButton>
        <CoreSpacer isHorizontal size="extraSmall" /> */}

        <HeaderButton onClick={onOutcomeOpen}>
          <FontAwesomeIcon icon="poll"></FontAwesomeIcon>
        </HeaderButton>
      </RightHeaderSection>
    </Container>
  );
};

export default Header;
