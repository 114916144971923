import React from "react";

interface Props {
  size: "extraSmall" | "small" | "medium" | "large" | "extraLarge";

  isHorizontal?: boolean;
}

const CoreSpacer: React.FC<Props> = ({ size, isHorizontal }) => {
  const getSize = () => {
    switch (size) {
      case "extraSmall": {
        return 5;
      }
      case "small": {
        return 10;
      }
      case "medium": {
        return 15;
      }
      case "large": {
        return 25;
      }
      case "extraLarge": {
        return 45;
      }
      default: {
        return 10;
      }
    }
  };

  const sizeNum = getSize();
  return (
    <div
      style={{
        height: isHorizontal ? 0 : sizeNum,
        width: isHorizontal ? sizeNum : 0,
        flexShrink: 0,
      }}
    ></div>
  );
};

export default CoreSpacer;
