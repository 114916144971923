import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import CoreSpacer from "./components/CoreSpacer";
import OutcomeModal from "./components/OutcomeModal";
import HelpModal from "./components/HelpModal";
import Tries from "./components/Tries";
import { COLOR } from "./constants/color";
import { getStartDayHourDiff, getStartOfDayString } from "./utils/time";
import {
  MatchState,
  Puzzle,
  SaveState,
  StartDayToDailyOutcome,
  TryOutcome,
} from "./types";
import { STORAGE_KEYS } from "./constants/storageKeys";
import InfoModal from "./components/InfoModal";
import Header from "./components/Header";
import {
  saveDailyOutcome,
  saveState,
  updateCurrentStreak,
  updateMaxStreak,
} from "./helpers/localStorage";
import Keyboard from "./components/Keyboard";
import { checkIfMobile } from "./utils/checkIfMobile";
import WordContainer, { WordOutcome } from "./components/WordContainer";

const HEADER_HEIGHT = 50;
const HEADER_BORDER_HEIGHT = 1;
const HEADER_SPACING = 10;
const MAX_GAME_WIDTH = 500;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: ${COLOR.BACKGROUND};
`;

const ContentContainer = styled.div`
  height: calc(
    100% - ${HEADER_HEIGHT + HEADER_BORDER_HEIGHT + HEADER_SPACING}px
  );
  width: 100%;
  max-width: ${MAX_GAME_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR.TEXT_COLOR};
  transition: height 0.2s;
`;

const PuzzleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 600px;
  max-height: 600px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 10px;
  width: 95%;
  flex: 1;
  flex-shrink: 0;
  border: 2px solid ${COLOR.BORDER};
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;

const PuzzleImage = styled.img`
  position: relative;
  width: 100%;
  height: 80%;
  object-fit: contain;
  box-sizing: content-box;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;

const WordsContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TriesContainer = styled.div`
  position: absolute;
  top: 5px;
`;

function App() {
  const [puzzle, setPuzzle] = useState<Puzzle>({
    answers: [],
    definition: "",
    imageUrl: "",
    backgroundColor: "white",
  });
  const [inputValueArray, setInputValueArray] = useState<string[][]>([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [currentTry, setCurrentTry] = useState(0);
  const [triesList, setTriesList] = useState([
    TryOutcome.None,
    TryOutcome.None,
    TryOutcome.None,
  ]);
  const [startDayToDailyOutcome, setStartDayToDailyOutcome] =
    useState<StartDayToDailyOutcome>({});
  const [currentStreak, setCurrentStreak] = useState(0);
  const [maxStreak, setMaxStreak] = useState(0);
  const [matchState, setMatchState] = useState(MatchState.Default);
  const [isOutcomeOpen, setIsOutcomeOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [wordOutcomeArray, setWordOutcomeArray] = useState<WordOutcome[]>([]);

  useEffect(() => {
    const mobile = checkIfMobile();
    if (mobile) {
      setIsMobile(true);
    } else {
      window.addEventListener("keypress", handleKeyPress);
    }

    loadPuzzle();
    loadDailyOutcome();
    loadCurrentStreak();
    loadMaxStreak();
    loadState();

    return () => {
      if (!mobile) {
        window.removeEventListener("keypress", handleKeyPress);
      }
    };
  }, []);

  function handleKeyPress(e: KeyboardEvent) {
    const key = e.key.toLowerCase();

    if (key.length !== 1) {
      return;
    }
    const isLetter = key >= "a" && key <= "z";
    if (isLetter) {
      onLetterPress(key);
    }
  }

  function loadState() {
    const saveStateString = localStorage.getItem(STORAGE_KEYS.DAILY_SAVE);
    if (saveStateString === null) {
      return;
    }

    const saveState = JSON.parse(saveStateString) as SaveState;
    const hourDiff = getStartDayHourDiff(saveState.lastSavedMS);

    if (hourDiff > 0) {
      setCurrentTry(saveState.currentTry);
      setMatchState(saveState.matchState);
      setTriesList(saveState.triesList);

      if (saveState.matchState !== MatchState.Default) {
        setIsOutcomeOpen(true);
      }
    }
  }

  function loadCurrentStreak() {
    const currentStreakString = localStorage.getItem(
      STORAGE_KEYS.CURRENT_STREAK_SAVE
    );
    if (currentStreakString === null) {
      return;
    }

    setCurrentStreak(parseInt(currentStreakString));
  }

  function loadMaxStreak() {
    const maxStreakString = localStorage.getItem(STORAGE_KEYS.MAX_STREAK_SAVE);
    if (maxStreakString === null) {
      return;
    }

    setMaxStreak(parseInt(maxStreakString));
  }

  function loadDailyOutcome() {
    const dailyOutcomeSaveString = localStorage.getItem(
      STORAGE_KEYS.DAILY_OUTCOME_SAVE
    );
    if (dailyOutcomeSaveString === null) {
      return;
    }

    const dailyOutcomeSave = JSON.parse(dailyOutcomeSaveString);
    setStartDayToDailyOutcome(dailyOutcomeSave);
  }

  async function loadPuzzle() {
    try {
      const res = await axios.get("/api/v1/puzzle");
      const puzzle: Puzzle = {
        answers: [...res.data.answers],
        definition: res.data.definition,
        imageUrl: res.data.imageUrl,
        backgroundColor: res.data.backgroundColor,
      };
      setPuzzle(puzzle);

      initializeInputValueArray(puzzle.answers[0]);
    } catch (e) {}
  }

  function initializeInputValueArray(answer: string) {
    const words = answer.split(" ");
    const blankInputValueArray = words.map((word) =>
      word.split("").map((_) => "")
    );
    setInputValueArray(blankInputValueArray);
    setWordOutcomeArray(words.map((_) => WordOutcome.None));
  }

  function onLetterPress(letter: string) {
    if (wordOutcomeArray[currentWordIndex] === WordOutcome.Correct) {
      return;
    }

    if (matchState != MatchState.Default) {
      return;
    }

    if (
      currentWordIndex === inputValueArray.length - 1 &&
      currentLetterIndex === inputValueArray[currentWordIndex].length
    ) {
      // No more letters to input
      return;
    }

    const inputValueArrayClone = [...inputValueArray];

    inputValueArrayClone[currentWordIndex][currentLetterIndex] = letter;
    setInputValueArray(inputValueArrayClone);

    if (currentLetterIndex + 1 < inputValueArray[currentWordIndex].length) {
      setCurrentLetterIndex(currentLetterIndex + 1);
    } else {
      let nextWordIndex = wordOutcomeArray.findIndex(
        (outcome, i) => outcome !== WordOutcome.Correct && i > currentWordIndex
      );
      if (nextWordIndex === -1) {
        nextWordIndex = currentWordIndex;
      } else {
        setCurrentLetterIndex(0);
      }

      setCurrentWordIndex(nextWordIndex);
    }
  }

  function onBackPress() {
    if (matchState != MatchState.Default) {
      return;
    }

    if (currentWordIndex === 0 && currentLetterIndex === 0) {
      return;
    }

    const inputValueArrayClone = [...inputValueArray];

    let prevLetterIndex = currentLetterIndex - 1;
    let prevWordIndex = currentWordIndex;
    if (currentLetterIndex === 0) {
      prevLetterIndex = inputValueArrayClone[currentWordIndex - 1].length - 1;
      prevWordIndex = currentWordIndex - 1;
    }

    inputValueArrayClone[prevWordIndex][prevLetterIndex] = "";

    setCurrentLetterIndex(prevLetterIndex);
    setCurrentWordIndex(prevWordIndex);
    setInputValueArray(inputValueArrayClone);
  }

  function onEnterPress() {
    if (matchState != MatchState.Default) {
      return;
    }

    playSubmitSequence();
  }

  function handleWordClick(wordIndex: number) {
    if (wordOutcomeArray[wordIndex] === WordOutcome.Correct) {
      return;
    }

    const firstNonLetter = inputValueArray[wordIndex].findIndex(
      (letter) => !letter
    );
    if (firstNonLetter === -1) {
      setCurrentWordIndex(wordIndex);
      setCurrentLetterIndex(0);
      return;
    }

    setCurrentWordIndex(wordIndex);
    setCurrentLetterIndex(firstNonLetter);
  }

  function playSubmitSequence() {
    const words = puzzle.answers[0].split(" ");
    const inputWords = getInputValueString().split(" ");

    const cloneArray = [...wordOutcomeArray];
    for (let i = 0; i < words.length; i++) {
      if (words[i] === inputWords[i]) {
        cloneArray[i] = WordOutcome.Correct;
      } else {
        cloneArray[i] = WordOutcome.Incorrect;
      }
    }

    setWordOutcomeArray(cloneArray);
  }

  function handleRevealComplete(wordIndex: number) {
    if (wordIndex < inputValueArray.length - 1) {
      return;
    }

    const updatedTriesList = [...triesList];

    const tryOutcome = getTryOutcome();
    updatedTriesList[currentTry] = tryOutcome;

    let updatedMatchState = MatchState.Default;
    let updatedStreak = currentStreak;

    if (tryOutcome == TryOutcome.Correct) {
      updatedMatchState = MatchState.Win;
      setMatchState(updatedMatchState);
      setIsOutcomeOpen(true);

      updatedStreak += 1;
      setCurrentStreak(updatedStreak);
      updateCurrentStreak(updatedStreak);
      if (updatedStreak > maxStreak) {
        setMaxStreak(updatedStreak);
        updateMaxStreak(updatedStreak, maxStreak);
      }
    } else if (currentTry + 1 >= 3) {
      updatedMatchState = MatchState.Defeat;
      setMatchState(updatedMatchState);
      setIsOutcomeOpen(true);

      updatedStreak = 0;
      setCurrentStreak(updatedStreak);
      updateCurrentStreak(updatedStreak);
    }

    const updatedCurrentTry = currentTry + 1;
    setTriesList(updatedTriesList);
    setCurrentTry(updatedCurrentTry);
    saveState({
      matchState: updatedMatchState,
      currentTry: updatedCurrentTry,
      triesList: updatedTriesList,
      lastSavedMS: new Date().getTime(),
    });

    const startOfDayString = getStartOfDayString();
    const startDayToDailyOutcomeClone = { ...startDayToDailyOutcome };
    startDayToDailyOutcomeClone[startOfDayString] = {
      startOfDayString,
      matchState: updatedMatchState,
      triesList: updatedTriesList,
    };
    saveDailyOutcome(startDayToDailyOutcomeClone);
    setStartDayToDailyOutcome(startDayToDailyOutcomeClone);

    // Only reset to incorrect words
    const inputValueArrayClone = [...inputValueArray];
    const wordOutcomeArrayClone = [...wordOutcomeArray];
    let firstIncorrectWordIndex = -1;
    for (let i = 0; i < wordOutcomeArray.length; i++) {
      if (wordOutcomeArray[i] === WordOutcome.Incorrect) {
        if (firstIncorrectWordIndex === -1) {
          firstIncorrectWordIndex = i;
        }

        inputValueArrayClone[i] = inputValueArrayClone[i].map((_) => "");
        wordOutcomeArrayClone[i] = WordOutcome.None;
      }
    }

    setInputValueArray(inputValueArrayClone);
    setWordOutcomeArray(wordOutcomeArrayClone);
    setCurrentWordIndex(
      firstIncorrectWordIndex === -1 ? 0 : firstIncorrectWordIndex
    );
    setCurrentLetterIndex(0);
  }

  function getInputValueString() {
    let inputValue = "";
    for (let i = 0; i < inputValueArray.length; i++) {
      for (let j = 0; j < inputValueArray[i].length; j++) {
        inputValue += inputValueArray[i][j];
      }

      inputValue += " ";
    }

    return inputValue;
  }

  function getTryOutcome() {
    const inputValue = getInputValueString();
    if (
      inputValue.trim().toLowerCase() == puzzle.answers[0].trim().toLowerCase()
    ) {
      return TryOutcome.Correct;
    }

    const splitAnswer = puzzle.answers[0]
      .split(/\s+/g)
      .map((w) => w.toLowerCase());
    let hasMatching = false;
    splitAnswer.forEach((word) => {
      if (inputValue.includes(word)) {
        hasMatching = true;
      }
    });
    if (hasMatching) {
      return TryOutcome.SemiCorrect;
    }

    return TryOutcome.Incorrect;
  }

  function onOutcomeClose() {
    setIsOutcomeOpen(false);
  }

  async function onShare() {
    const triesString = triesList
      .map((outcome) => {
        switch (outcome) {
          case TryOutcome.Correct: {
            return "🟢";
          }
          case TryOutcome.SemiCorrect: {
            return "🟡";
          }
          case TryOutcome.Incorrect: {
            return "🔴";
          }
          default: {
            return "⚫";
          }
        }
      })
      .toString()
      .replaceAll(",", " ");
    const title = "Daily Rebus";
    const text = "What does it mean?\n\nMy Daily Rebus Results\n" + triesString;
    const url = window.location.href;
    if (navigator.share !== undefined) {
      await navigator.share({
        title,
        text,
        url,
      });
    } else {
      // copy to clipboard
    }
  }

  function onOutcomeOpen() {
    setIsOutcomeOpen(true);
  }

  function onHelpOpen() {
    setIsHelpOpen(true);
  }

  function onHelpClose() {
    setIsHelpOpen(false);
  }

  function onInfoOpen() {
    setIsInfoOpen(true);
  }

  function onInfoClose() {
    setIsInfoOpen(false);
  }

  return (
    <Container>
      <OutcomeModal
        isVisible={isOutcomeOpen}
        answer={puzzle.answers[0]}
        answerDefinition={puzzle.definition}
        matchState={matchState}
        triesList={triesList}
        startDayToDailyOutcome={startDayToDailyOutcome}
        currentStreak={currentStreak}
        maxStreak={maxStreak}
        onShare={onShare}
        onClose={onOutcomeClose}
      />
      <HelpModal isVisible={isHelpOpen} onClose={onHelpClose} />
      <InfoModal isVisible={isInfoOpen} onClose={onInfoClose} />

      <Header
        onMenuOpen={() => {}}
        onHelpOpen={onHelpOpen}
        onOutcomeOpen={onOutcomeOpen}
        onInfoOpen={onInfoOpen}
      />

      <ContentContainer>
        <PuzzleContainer backgroundColor={puzzle.backgroundColor}>
          <PuzzleImage
            src={puzzle.imageUrl}
            backgroundColor={puzzle.backgroundColor}
          />

          <TriesContainer>
            <Tries triesList={triesList} />
          </TriesContainer>

          <WordsContainer>
            {inputValueArray.map((word, wordIndex) => (
              <>
                <WordContainer
                  outcome={wordOutcomeArray[wordIndex]}
                  word={word}
                  wordIndex={wordIndex}
                  currentWordIndex={currentWordIndex}
                  currentLetterIndex={currentLetterIndex}
                  onRevealComplete={handleRevealComplete}
                  onClick={handleWordClick}
                />
                <CoreSpacer size="extraSmall" />
              </>
            ))}
          </WordsContainer>
        </PuzzleContainer>
        <CoreSpacer size="small" />

        {true ? (
          <Keyboard
            onBackPress={onBackPress}
            onEnterPress={onEnterPress}
            onLetterPress={onLetterPress}
          />
        ) : null}
        <CoreSpacer size="small" />
      </ContentContainer>
    </Container>
  );
}

export default App;
