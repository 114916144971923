export const COLOR = {
  BACKGROUND: "#111",
  BORDER: "#555",
  LIGHTER_GRAY: "#444",
  KEYBOARD: "#666",

  TEXT_COLOR: "#FFF",
  PLACEHOLDER: "#808080",

  CORRECT_TRY: "#43AA8B",
  SEMI_CORRECT_TRY: "#F9C74F",
  INCORRECT_TRY: "#F94144",

  REVEAL_HIDER: "#666",
};
