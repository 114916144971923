import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import examplePuzzle from "../images/example-puzzle.png";

import { COLOR } from "../constants/color";
import CoreSpacer from "./CoreSpacer";
import RevealText from "./RevealText";
import Try from "./Try";
import { TryOutcome } from "../types";

const MAX_MODAL_WIDTH = 500;

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;

  &.visible {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 80vw;
  height: fit-content;
  padding: 10px;
  max-width: ${MAX_MODAL_WIDTH}px;
  background-color: ${COLOR.BACKGROUND};
  border: 2px solid ${COLOR.BORDER};
`;

const HeaderText = styled.h1`
  color: ${COLOR.TEXT_COLOR};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${COLOR.TEXT_COLOR};
  font-size: 24px;
  cursor: pointer;
`;

const Article = styled.article`
  color: ${COLOR.TEXT_COLOR};
  font-size: 15px;
  text-align: center;
`;

const Paragraph = styled.p``;

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const InfoModal: React.FC<Props> = ({ isVisible, onClose }) => {
  function onModalClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const visibilityClass = isVisible ? "visible" : "";

  return (
    <Container className={visibilityClass} onClick={onClose}>
      <ModalContainer onClick={onModalClick}>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon="close" />
        </CloseButton>

        <HeaderText>About</HeaderText>
        <CoreSpacer size="large" />

        <Article>
          <b>Hello puzzler!</b>
          <CoreSpacer size="medium" />

          <Paragraph>
            I am the sole creator of Daily Rebus. All of the puzzles are
            original and drawn by me. My goal is to create a game that helps you
            engage your mind while also having fun. :)
          </Paragraph>
          <CoreSpacer size="small" />

          <Paragraph>
            If you enjoy the game and puzzles, please share it with your
            friends/family and on social media.
          </Paragraph>
          <CoreSpacer size="small" />

          <Paragraph>
            P.S. Some folks have asked if they’re able to view puzzles I’ve made
            for previous days. At the moment, you cannot but I am working on an
            “archive” type system so that folks can enjoy some of my past
            creations.
          </Paragraph>
          <CoreSpacer size="small" />

          <b>Sincerely,</b>
          <CoreSpacer size="medium" />
          <b>Andrew (Ajackster)</b>
        </Article>
      </ModalContainer>
    </Container>
  );
};

export default InfoModal;
