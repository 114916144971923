export function getStartOfDay() {
  const startOfTodayReset = new Date();
  startOfTodayReset.setUTCHours(24);
  startOfTodayReset.setMinutes(0);
  startOfTodayReset.setSeconds(0);
  startOfTodayReset.setMilliseconds(0);

  if (startOfTodayReset.getTime() > Date.now()) {
    const todaysResetPeriod = new Date(
      startOfTodayReset.getTime() - 24 * 60 * 60 * 1000
    );
    return todaysResetPeriod.getTime();
  }

  return startOfTodayReset.getTime();
}

export function getStartOfDayString() {
  const date = new Date(getStartOfDay());
  const dateString = date.toISOString().substring(0, 10);

  return dateString;
}

export function padTime(n: number) {
  return n < 10 ? "0" + n : n.toString();
}

export function getStartDayHourDiff(lastSavedMS: number) {
  const hourDiff = (lastSavedMS - getStartOfDay()) / (1000 * 60 * 60);
  return hourDiff;
}
