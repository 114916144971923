import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import examplePuzzle from "../images/example-puzzle.png";

import { COLOR } from "../constants/color";
import CoreSpacer from "./CoreSpacer";
import RevealText from "./RevealText";
import Try from "./Try";
import { TryOutcome } from "../types";

const MAX_MODAL_WIDTH = 500;

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;

  &.visible {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 80vw;
  height: 60vh;
  padding: 10px;
  max-width: ${MAX_MODAL_WIDTH}px;
  background-color: ${COLOR.BACKGROUND};
  border: 2px solid ${COLOR.BORDER};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${COLOR.TEXT_COLOR};
  font-size: 24px;
  cursor: pointer;
`;

const HeaderText = styled.h1`
  text-align: center;
  color: ${COLOR.TEXT_COLOR};
`;

const DescriptionText = styled.p`
  color: ${COLOR.TEXT_COLOR};
`;

const SubHeaderText = styled.h2`
  text-align: center;
  color: ${COLOR.TEXT_COLOR};
`;

const ExampleImage = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain;
  background-color: white;
  border: 1px solid ${COLOR.BORDER};
`;

const ListItem = styled.li`
  color: ${COLOR.TEXT_COLOR};
  font-size: 16px;
`;

const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TryContainer = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
`;

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<Props> = ({ isVisible, onClose }) => {
  function onModalClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const visibilityClass = isVisible ? "visible" : "";

  return (
    <Container className={visibilityClass} onClick={onClose}>
      <ModalContainer onClick={onModalClick}>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon="close" />
        </CloseButton>

        <HeaderText>How To Play</HeaderText>
        <CoreSpacer size="small" />

        <DescriptionText>
          Guess the word/phrase that is being depicted in the image.
        </DescriptionText>
        <CoreSpacer size="medium" />

        <SubHeaderText>Example</SubHeaderText>
        <CoreSpacer size="extraSmall" />

        <ExampleImage src={examplePuzzle} />
        <CoreSpacer size="extraSmall" />

        <RevealText text="Parallel Parking" />
        <CoreSpacer size="medium" />

        <RulesContainer>
          <SubHeaderText>When entering the answer</SubHeaderText>
          <ul>
            <ListItem>Casing DOES NOT matter</ListItem>
            <ListItem>
              Order of words DO matter e.g. Parallel Parking != Parking Parallel
            </ListItem>
          </ul>
        </RulesContainer>

        <TryContainer>
          <Try outcome={TryOutcome.Correct} />
          <CoreSpacer isHorizontal size="small" />
          <DescriptionText>Correct</DescriptionText>
        </TryContainer>
        <TryContainer>
          <Try outcome={TryOutcome.SemiCorrect} />
          <CoreSpacer isHorizontal size="small" />
          <DescriptionText>
            Not completely correct - part of your guess was in the answer
          </DescriptionText>
        </TryContainer>
        <TryContainer>
          <Try outcome={TryOutcome.Incorrect} />
          <CoreSpacer isHorizontal size="small" />
          <DescriptionText>Incorrect guess</DescriptionText>
        </TryContainer>
      </ModalContainer>
    </Container>
  );
};

export default HelpModal;
